.smart-scroll::-webkit-scrollbar {
  width: 6px;
}

.smart-scroll::-webkit-scrollbar-track {
  background: none;
}

.smart-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  background: #C4CDD5;
  border-radius: 3px;
}

.smart-scroll::-webkit-scrollbar-thumb:hover {
  background: #919EAB;
}

.grow {
  transition: all 300ms;
}

.grow:hover {
  transform: scale(1.09);
}